import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import number_1 from '../images/number-1.png';
import number_2 from '../images/number-2.png';
import google_icon from '../images/adwords.png';
import facebook_icon from '../images/facebook.png';
import connected_icon from '../images/correct.png';
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';


const Home = ({ViewedPage, shop_domain, app_url}) => {
    ViewedPage(4)

    // Render Select Ads Account and conversion action setting
    const [show_google_login_btn, setShowGoogleLogin] = useState(true);
    const [show_facebook_login_btn, setShowFacebookLogin] = useState(true);
    const [Step1Indicator, setStep1Indicator] = useState(!show_google_login_btn);
    const [Step2Indicator, setStep2Indicator] = useState(false);
    const [google_loading, setGoogleLoading] = useState(true);
    const [facebook_loading, setFacebookLoading] = useState(true);

    const handle_sign_out = async (e) => {
        e.preventDefault();
        setGoogleLoading(true);
        try {
            if(Cookies.get('shop')){
                const { data } = await axios.post(app_url+"/api/google/sign_out",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true});
                if(data.success){
                    toast.success(data.message);
                    setGoogleLoading(false);
                    setShowGoogleLogin(true);
                    setStep1Indicator(false);
                    setStep2Indicator(false);
                }
                else{
                    setGoogleLoading(false);
                    toast.error(data.message);
                }
            }
        } catch (error) {
            setGoogleLoading(false);
            toast.error("Please try again.");
        }
    };

    const handle_facebook_sign_out = async (e) => {
        e.preventDefault();
        setFacebookLoading(true);
        try {
            if(Cookies.get('shop')){
                const { data } = await axios.post(app_url+"/api/facebook/sign_out",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true});
                if(data.success){
                    toast.success(data.message);
                    setFacebookLoading(false);
                    setShowFacebookLogin(true);
                    setStep1Indicator(false);
                    setStep2Indicator(false);
                }
                else{
                    setFacebookLoading(false);
                    toast.error(data.message);
                }
            }
        } catch (error) {
            setFacebookLoading(false);
            toast.error("Please try again.");
        }
    };
  
    useEffect(() => {
        setShowGoogleLogin(true)
        setShowFacebookLogin(true)
        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/google/get_loader_data",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    if(data.success){
                        var temp_loader_data = data.data;
                        setShowGoogleLogin(temp_loader_data["show_google_login_btn"]);
                        setStep1Indicator(!temp_loader_data["show_google_login_btn"]);
                        setGoogleLoading(false);
                    }
                })
                .catch(error => {
                    setGoogleLoading(false);
                    toast.error("Google Data not synced.")
                })
                axios.post(app_url+"/api/facebook/get_loader_data",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    if(data.success){
                        var temp_loader_data = data.data;
                        setShowFacebookLogin(temp_loader_data["is_show_continue_with_facebook_btn"]);                        
                        setStep2Indicator(!temp_loader_data["is_show_continue_with_facebook_btn"]);
                        setFacebookLoading(false);
                    }
                })
                .catch(error => {
                    setFacebookLoading(false);
                    toast.error(error)
                })
            }
        } catch (error) {
            setGoogleLoading(false);
            setFacebookLoading(false);
            toast.error(error)
        }
    }, [app_url, shop_domain]);
    
    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h6 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h6>
                </div>
                :
                <>
                    { ((google_loading !== true) && (facebook_loading !== true)) ?
                        <>
                        <div className='main-title mt-4'>
                            <h2>Google Ads Integration</h2>
                        </div>
                        <div className='main-accordion-body mt-4 p-2'>
                        <Accordion defaultActiveKey={ ["0","1"]} alwaysOpen>
                            {/* Step - 1 (Google Ads Integration) */}
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <img src={number_1} alt='1' width={"15px"} height={"15px"} /> 
                                    <b style={{marginLeft:"10px"}}>Integrate Google Ads</b> 
                                    {/* { Step1Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> } */}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Container className='google_and_meta_integration_accordion_container'>
                                        <Row>
                                            <Col sm={8}>Connect Google Ads Account with Shopify to get started.</Col>
                                            <Col sm={4} className='google_and_meta_integration_status_col'>
                                            { show_google_login_btn === true &&
                                                <button style={{backgroundColor:"#4285f4", color:"white", width:"8rem",border:"none","padding": "2px 10px 2px 2px","cursor":" pointer", display:"flex", alignItems:"center"}} onClick={() => {
                                                    setGoogleLoading(true);
                                                    axios.post(app_url+"/api/google/google_sign_in",{
                                                        "shop_domain":shop_domain.replace(".myshopify.com",""),
                                                    }, {withCredentials:true})
                                                    .then((response) => response.data)
                                                    .then((data) => {
                                                        setGoogleLoading(false);
                                                        window.location.href = data["data"]["redirect_url"];
                                                    })
                                                    .catch((error) => {
                                                        setGoogleLoading(false);
                                                        toast.error("Please try again.")
                                                    });
                                                }}>
                                                <img style={{background:"white", padding:"6px", marginRight:"10px"}} alt='Google Ads' src={google_icon} width={"30px"}/> 
                                                <h6 style={{margin:"auto"}}>
                                                    Sign In
                                                </h6>
                                                </button>
                                            }
                                            { show_google_login_btn === false &&
                                                <button style={{color:"blue", width:"8rem", border:"none", background:"transparent", "cursor":" pointer", display:"flex", alignItems:"center"}}
                                                onClick={handle_sign_out}
                                                >
                                                <h6 style={{margin:"auto"}}>Sign Out</h6>
                                                </button>
                                            }
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Step - 2 (Facebook Ads Integration) */}
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <img src={number_2} alt='2' width={"15px"} height={"15px"} /> 
                                    <b style={{marginLeft:"10px"}}>Integrate Facebook Ads</b> 
                                    {/* { Step2Indicator && <img alt='connected' style={{padding:"6px", marginRight:"10px"}} src={connected_icon} width={"30px"}/> } */}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Container className='google_and_meta_integration_accordion_container'>
                                        <Row>
                                            <Col sm={8}>Connect Facebook Ads Account with Shopify to get started.</Col>
                                            <Col sm={4} className='google_and_meta_integration_status_col'>
                                            { show_facebook_login_btn === true &&
                                                <button style={{backgroundColor:"#4285f4", color:"white", width:"8rem",border:"none","padding": "2px 10px 2px 2px","cursor":" pointer", display:"flex", alignItems:"center"}} onClick={() => {
                                                    setFacebookLoading(true);
                                                    axios.post(app_url+"/api/facebook/facebook_sign_in",{
                                                        "shop_domain":shop_domain.replace(".myshopify.com",""),
                                                    }, {withCredentials:true})
                                                    .then((response) => response.data)
                                                    .then((data) => {
                                                        setFacebookLoading(false);
                                                        window.location.href = data["data"]["redirect_url"];
                                                    })
                                                    .catch((error) => {
                                                        setFacebookLoading(false);
                                                        toast.error("Please try again.")
                                                    });
                                                }}>
                                                    <img style={{background:"white", padding:"6px", marginRight:"10px"}} alt='Facebook Ads' src={facebook_icon} width={"30px"}/> 
                                                    <h6 style={{margin:"auto"}}>
                                                        Sign In
                                                    </h6>
                                                </button>
                                            }
                                            { show_facebook_login_btn === false &&
                                                <button style={{color:"blue", width:"8rem", border:"none", background:"transparent", "cursor":" pointer", display:"flex", alignItems:"center"}}
                                                onClick={handle_facebook_sign_out}
                                                >
                                                <h6 style={{margin:"auto"}}>Sign Out</h6>
                                                </button>
                                            }
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        </div>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default Home
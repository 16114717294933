import React, { useState } from 'react';
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {showToast as toast} from '../toastManager';
import axios from 'axios';
import Cookies from 'js-cookie';

function Login({ app_url }) {
    const [login_loading_btn, setLoginLoading] = useState(false);
    const [otp_loading_btn, setOTPLoading] = useState(false);
    const [show_opt_div, setShowOTPDiv] = useState(false);
    const [email, setEmail] = useState('');
    const [otp_value, setOTPValue] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();


    const [errors, setErrors] = useState({});


    // Handle Send OTP
    const handleSendOTP = (e) => {
        e.preventDefault();

        setLoginLoading(true);

        if(validateForm()){
            try {
                axios.post(app_url+"/send_otp",{email:email, password:password}, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    setLoginLoading(false);
                    setShowOTPDiv(true);
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message){
                        toast.error(err.response.data.message);
                    }
                    else{
                        toast.error("Login Failed.");
                    }
                    console.log(err);
                    setLoginLoading(false);
                })
            } catch (error) {
                toast.error("Please try after some time.");
                setLoginLoading(false);
            }
        }
        else{
            setLoginLoading(false);
        }
    }


    // Validate inputs and set custom error messages
    const validateForm = () => {
        const newErrors = {};

        if ((email === null) || (email === undefined) || (email.trim() === '')) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Please enter a valid email address.';
        }

        if ((password === null) || (password === undefined) ||  (password.trim() === '')) {
            newErrors.password = 'Password is required.';
        }

        if(show_opt_div){
            if (!otp_value) {
                newErrors.otp = 'Please enter OTP.';
            }
            else if (otp_value < 0) {
                newErrors.otp = 'OTP should not be negative.';
            }
            else if (`${otp_value}`.length !== 6) {
                newErrors.otp = 'OTP must be 6 digits.';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOTPLoading(true);
    if(validateForm()){
        try {
            axios.post(app_url+"/login",{email:email, password:password, otp:otp_value}, {withCredentials:true})
            .then(res => res.data)
            .then(data => {
                setLoginLoading(false);
                if(data["success"] === true){
                    console.log(data)
                    Cookies.set('stores', data["data"]["stores"], {
                        sameSite: "strict", // Use "strict" or "lax" for security
                        secure: false,      // Set to true if using HTTPS
                        expires: 0.5,       // Expiration in days (e.g., 12 hours = 0.5 days)
                    });
                    Cookies.set('allowed_pages_json', data["data"]["allowed_pages_json"], {
                        sameSite: "strict", // Use "strict" or "lax" for security
                        secure: false,      // Set to true if using HTTPS
                        expires: 0.5,       // Expiration in days (e.g., 12 hours = 0.5 days)
                    });
                    Cookies.set('shop_name', data["data"]["shop_name"], {
                        sameSite: "strict", // Use "strict" or "lax" for security
                        secure: false,      // Set to true if using HTTPS
                        expires: 0.5,       // Expiration in days (e.g., 12 hours = 0.5 days)
                    });
                    Cookies.set('shop', data["data"]["shop"], {
                        sameSite: "strict", // Use "strict" or "lax" for security
                        secure: false,      // Set to true if using HTTPS
                        expires: 0.5,       // Expiration in days (e.g., 12 hours = 0.5 days)
                    });
                    Cookies.set('show_under_dev_pages', data["data"]["show_under_dev_pages"], {
                        sameSite: "strict", // Use "strict" or "lax" for security
                        secure: false,      // Set to true if using HTTPS
                        expires: 0.5,       // Expiration in days (e.g., 12 hours = 0.5 days)
                    });
                    navigate('/', {replace: true});
                    window.location.reload();
                }
                else{
                    toast.error(data["message"]);
                }
                setOTPLoading(false)
            })
            .catch(err => {
                if(err && err.response && err.response.data && err.response.data.message){
                    toast.error(err.response.data.message);
                }
                else{
                    toast.error("Login Failed.");
                }
                console.log(err);
                setOTPLoading(false)
            })
        } catch (error) {
            toast.error("Please try after some time.");
            setOTPLoading(false)
        }
    }
    else{
        setOTPLoading(false)
    }
  };

  return (
    <>
        <Toaster />
        <div className='login-main-container'>
            {/* Details side */}
            <div className='login-details-part'>
                <div style={{ width: '100%', textAlign:"center" }}>
                    <p style={{color:"white", fontSize:"60px", fontWeight:"bold"}}>ScaleX AI</p>
                </div>
            </div>
            
            {/* Login Side */}
            <div className='login-form-part'>
                <div className='login-form-div'>
                    <h1 className="text-center">Login</h1>
                    <br />
                    <Form  onSubmit={e => { e.preventDefault(); }} noValidate>
                        { show_opt_div ?
                            <>
                                <Form.Group className='mb-3' controlId="formBasicOTP">
                                    <Form.Label>OTP</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter OTP"
                                        min={100000}
                                        max={1000000}
                                        value={otp_value}
                                        onChange={(e) => setOTPValue(e.target.value.trim())}
                                        isInvalid={!!errors.otp}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.otp} {/* Show custom email error message */}
                                        </Form.Control.Feedback>
                                </Form.Group>
                                

                                { otp_loading_btn ?
                                    <Button variant="primary" type="button" disabled className="w-100 mt-3" style={{ backgroundColor: 'rgb(19,40,72)', color:"white", borderColor: '#007bff' }}>
                                        <Spinner size='sm' />
                                    </Button>
                                :
                                    <Button variant="primary" type="submit" onClick={handleSubmit} className="w-100 mt-3" style={{ backgroundColor: 'rgb(19,40,72)', color:"white", borderColor: '#007bff' }}>
                                        Verify OTP
                                    </Button>
                                }
                            </>
                        :
                            <>
                                <Form.Group className='mb-3' controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value.trim())}
                                        isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email} {/* Show custom email error message */}
                                        </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className='mt-3' controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value.trim())}
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password} {/* Show custom email error message */}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <br />

                                { login_loading_btn ?
                                    <Button variant="primary" type="button" disabled className="w-100 mt-3" style={{ backgroundColor: 'rgb(19,40,72)', color:"white", borderColor: '#007bff' }}>
                                        <Spinner size='sm' />
                                    </Button>
                                :
                                    <Button variant="primary" type="button" onClick={handleSendOTP} className="w-100 mt-3" style={{ backgroundColor: 'rgb(19,40,72)', color:"white", borderColor: '#007bff' }}>
                                        Login
                                    </Button>
                                }
                            </>
                        }
                    </Form>

                    <br />

                    <hr />

                    <Link to={"https://admin.shopify.com/"} target="_blank"><Button variant="primary" type="submit" className="w-100 mt-3" style={{ backgroundColor: 'rgb(19,40,72)', color:"white", borderColor: '#007bff' }}>Login with Shopify</Button></Link>

                </div>
            </div>
        </div>
    </>
  );
}

export default Login;


import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import axios from 'axios';


function SignOut({ app_url, shop_domain }){
    try{
        axios.post(`${app_url}/api/signout`, {shop_domain:shop_domain})
        .then(res => res.data)
        .then(data => {
            const allCookies = Cookies.get(); // Get all cookies
            Object.keys(allCookies).forEach((cookieName) => {
                Cookies.remove(cookieName); // Remove each cookie
            });
            sessionStorage.clear();
            localStorage.clear();
            window.location.reload();
        })
        .catch(err => {
            console.log(err);
            toast.error("Sign-out failed");
        })
    }
    catch(e){
        console.log(e);
        toast.error("Sign-out failed");
    }
}

export default SignOut;
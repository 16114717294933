import React, { useState, useEffect } from "react";

const Typewriter = ({ text = "", speed = 50 }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    if (!text || text.length === 0) return; // Early exit if text is empty or null

    let currentIndex = 0;

    const interval = setInterval(() => {
      if (currentIndex < text.length) {
        // Append current character if valid
        const currentChar = text[currentIndex];
        setDisplayedText((prev) => prev + (currentChar || ""));
        currentIndex++;
      } else {
        clearInterval(interval); // Stop interval after all text is displayed
      }
    }, speed);

    // Clean up interval on component unmount or text change
    return () => clearInterval(interval);
  }, [text, speed]);

  return (
    <pre className="ai-message">
      {displayedText.replaceAll("#", "").replaceAll("*", "")}
    </pre>
  );
};

export default Typewriter;
